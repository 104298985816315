import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/workspace/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "configuration"
    }}>{`Configuration`}</h1>
    <p>{`Ocelot is fully adopting the set of best-practices known as `}<a parentName="p" {...{
        "href": "https://12factor.net/"
      }}>{`The Twelve-Factor App`}</a>{`.`}</p>
    <p>{`In the following we will keep updated a list of environment variables you can use to modify the behavior of Ocelot.`}</p>
    <h2 {...{
      "id": "generic-environment-variables"
    }}>{`Generic Environment Variables`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Environment Variable`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default Value`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Required`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`OCELOT_LOG_LEVEL`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`INFO`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`no`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Log level. It can be set to TRACE, DEBUG, INFO, WARN, ERROR`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`CLIENT_ID_REQUEST_HEADER_NAME`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`X-ClientID`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`no`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Name of the header containing the Client Ids for a request forged by an external system`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`CLIENT_SECRET_REQUEST_HEADER_NAME`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`X-ClientSecret`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`no`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Name of the header containing the Client Secret for a request forged by an external system`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`LEAF_CERT_PRINCIPAL`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`CN=Gino Pino, OU=YanchWare, O=YanchWare, L=Aarhus, ST=Denmark, C=DK`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`yes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The information about the leaf certificate needed to verify the internal tokens`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`PATH_TO_KEY_STORE`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`./secrets/keystore.jks`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`yes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Path to the key store containing the key used to sign internal tokens`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`KEY_STORE_PWD`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`teststore`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`yes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Password used to protect access to the key store`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`KEY_ALIAS`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`test-alias`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`yes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Alias of the private key that needs to be used for signing the internal tokens`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`KEY_PWD`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`test12`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`yes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Password used to protect access to the private key`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`CORS_METHODS`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`GET,POST,PUT,DELETE`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`no`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Comma-separated HTTP Verbs allowed for CORS requests`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`CORS_ORIGINS`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "http://127.0.0.1:8080"
            }}>{`http://127.0.0.1:8080`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`no`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Comma-separated list of CORS allowed origins`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`CORS_HEADERS`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`X-ClientID,Content-Type,Authorization,X-ClientSecret`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`no`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Comma-separated list of Http Header names allowed for CORS requests`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`COOKIE_MAX_AGE_SEC`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`300`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`no`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Deprecated. Please use SESSION_TTL_MINUTES instead`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`SESSION_TTL_MINUTES`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`10`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`no`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Maximal amount of time a session can be idle before being terminated`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`DB_SECRET_ID`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`ocelot-db-credentials`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`no`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Name of the secret containing the credentials needed to access the ocelot database`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`Beside these generic settings, we have more specific flags for each supported cloud vendor.`}</p>
    <h2 {...{
      "id": "gcp-environment-variables"
    }}>{`GCP Environment Variables`}</h2>
    <p>{`These variables are relevant exclusively if you are running in Google Cloud Platform.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Environment Variable`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default Value`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Required`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`PROJECT_NAME`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`my-project`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`yes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The name of the GCP project in which Ocelot is running`}</td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "azure-environment-variables"
    }}>{`Azure Environment Variables`}</h2>
    <p>{`These variables are relevant exclusively if you are running in Microsoft Azure.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Environment Variable`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default Value`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Required`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`TENANT_ID`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`00000000-0000-0000-0000-000000000000`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`yes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The Azure Tenant ID owning the subscription in which the KeyVault for Ocelot is hosted.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`KEY_VAULT_SUBSCRIPTION_ID`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`00000000-0000-0000-0000-000000000000`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`yes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The ID of the Subscription in which the KeyVault for Ocelot is hosted.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`KEY_VAULT_RG_NAME`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`myBeautifulKeyVault`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`yes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The name of the Resource Group in which the KeyVault for Ocelot has been instantiated.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`KEY_VAULT_NAME`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`myBeautifulKeyVaultResourceGroup`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`yes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The name of the KeyVault for Ocelot.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`AZURE_TENANT_ID`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`empty`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`no`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TThe Azure Active Directory tenant(directory) ID in which the Service Principal created for ocelot is defined, if any.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`AZURE_CLIENT_ID`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`empty`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`no`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The client(application) ID of a Service Principal in the tenant that you would like Ocelot to impersonate.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`\`AZURE_CLIENT_SECRET`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`empty`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`no`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A client secret that was generated for the Service Principal.`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      